/**
 * This class defines methods for apps to communicate it's state to the Player.
 */

import * as pkg from '../package.json';
import Bridge from './bridge/bridge';
import { Action, Service } from './enums';

/**
 * AppStatus API is a part of the Player SDK used for managing app's lifecycle and state.
 * Use global `enplug.appStatus` object to access these methods.
 *
 * ```typescript title="Example usage of the AppStatus API"
 * await enplug.appStatus.start();
 * ```
 *
 * Useful commands:
 * - {@link start|`enplug.appStatus.start()`} - app is ready
 * - {@link error|`enplug.appStatus.error()`} - app has errored and cannot be displayed
 * - {@link hide|`enplug.appStatus.hide()`} - app should be hidden
 * - {@link setCanInterrupt|`enplug.appStatus.setCanInterrupt()`} - informs whether the app can be hidden
 */
export default class AppStatus {
  private canInterruptInternal = true;
  private version = (pkg as any).version as string;
  private serviceWorkerTimer;

  /** @internal */
  constructor(private bridge: Bridge) { }

  /**
   * Registers a service worker to enable offline support for the app.
   *
   * @param appId - Optional app ID. Appended to logs for easier debugging.
   * @param swFilePath - Optional path to the service worker file. Defaults to Enplug apps' 'enplug-offline-worker.js'
   * @returns Promise resolves when service worker registration was a success. It rejects when it was a failure.
   */
  registerServiceWorker(appId?: string, swFilePath?: string): Promise<string> {
    const SW_TAG = appId ? `[${appId} | Service Worker]` : '[Service Worker]';
    try {
      swFilePath = swFilePath || './enplug-offline-worker.js';
      return new Promise((resolve, reject) => {
        if (
          'serviceWorker' in navigator &&
          navigator.userAgent.indexOf('iPad') < 0 &&
          navigator.userAgent.indexOf('iPhone') < 0
        ) {
          navigator.serviceWorker.register(swFilePath).then((registration) => {
            console.log(`${SW_TAG} ServiceWorker registration successful with scope: `, registration.scope);

            this.serviceWorkerTimer = setTimeout(() => {
              // If server worker fails to resolve its state in 10 seconds, we assume it failed. This is done as
              // a safeguard to ensure that no matter what, the app will be started.
              console.log(`${SW_TAG} Service worker failed to resolve it state to activated.`);
              console.log(`${SW_TAG} Current sw registration: ${JSON.stringify(registration)}`);
              reject();
            }, 10000);

            // We need to check whether service worker is in a non-active state. If so, we listen for a state change
            // to "active".
            const sw = registration.installing || registration.waiting;
            // Possible states:
            //   installing - the install event has fired, but not yet complete
            //   installed  - install complete
            //   activating - the activate event has fired, but not yet complete
            //   activated  - fully active
            //   redundant  - discarded. Either failed install, or it's been replaced by a newer version
            if (sw) {
              sw.addEventListener('statechange', (stateEvent) => {
                const state = stateEvent && stateEvent.target && stateEvent.target['state'];
                console.log(`${SW_TAG} Service worker state changed to: ${state}`);
                if (registration.active) {
                  console.log(`${SW_TAG} Service worker has become active.`);
                  clearTimeout(this.serviceWorkerTimer);
                  resolve(null);
                  return;
                } else if (state === 'activated') {
                  console.log(`${SW_TAG} Service worker has been activated`);
                  clearTimeout(this.serviceWorkerTimer);
                  resolve(null);
                } else if (state === 'redundant') {
                  console.log(`${SW_TAG} Service either failed to install or has been replaced by newer version.`);
                  clearTimeout(this.serviceWorkerTimer);
                  reject();
                }
              });
            } else if (!sw && registration.active) {
              // Service worker is already active. No need to wait for statechange. This will always be true when
              // we're offline.
              console.log(`${SW_TAG} Service worker is active.`);
              clearTimeout(this.serviceWorkerTimer);
              resolve(null);
            }
          }, (err) => {
            // Registration failed
            console.warn(`${SW_TAG}ServiceWorker registration failed: ${err}`, err);
            clearTimeout(this.serviceWorkerTimer);
            reject(`${SW_TAG} Error registering service worker: ${JSON.stringify(err)}`);
          });
        } else {
          clearTimeout(this.serviceWorkerTimer);
          reject(`${SW_TAG} Browser does not support service workers. User agent: ${navigator.userAgent}`);
        }
      });
    } catch (err) {
      console.error(`${SW_TAG} Error inside registerServiceWorker(). ${err}`);
      clearTimeout(this.serviceWorkerTimer);
      return Promise.reject();
    }
  }

  /**
   * Informs the Player that the app is ready to be shown on screen.
   *
   * When an app is first started by the Player it will not be shown on screen
   * until it explicitly tells the Player that it is **ready to be rendered**.
   *
   * Note that initially apps are loaded off screen so they can be given time to properly initialize.
   * Your app can set up itself, preload resources and calculate layout, so there is not flickering
   * or loading visible when the app gets shown on the screen.
   *
   * When the app is ready to be shown, call the `start` function to be entered into the current
   * rotation of active Player Applications.
   *
   * ```typescript title="Informing the Player that the app is ready"
   * enplug.appStatus.start();
   * ```
   *
   * :::note
   * Calling `start` informs the Player that the app is ready to be displayed,
   * however it does not guarantee that it will be displayed immediately.
   * The returned Promise will resolve after the app gets shown on screen.
   * :::
   *
   * @returns Resolves to true if the operation has completed successfully and the app is presented on screen.
   */
  start(): Promise<boolean> {
    return this.bridge.send(Service.AppStatus, Action.Start);
  }

  /**
   * Informs the Player that the app should be hidden.
   *
   * If ever you want to hide your application the `hide` function can be called to do so.
   * Calling this function will hide your application until it comes up in the normal application rotation cycle.
   *
   * :::note
   * This will inform the Player that the app should be hidden as soon as possible.
   * It might not happen immediately as the Player might need some time to prepare and get another app ready.
   * :::
   *
   * ```typescript title="Informing the Player that the app should be hidden"
   * enplug.appStatus.hide();
   * ```
   *
   * :::caution
   * If your app is the only app playing on the display it will not be hidden.
   * :::
   *
   * @returns Resolves to true when the app has been hidden from the screen.
   */
  hide(): Promise<boolean> {
    return this.bridge.send(Service.AppStatus, Action.Hide);
  }

  /**
   * Informs the Player about an error with the app.
   *
   * It is important to notify the Player if your application has reached an unresolvable error.
   * Calling the error function will notify the Player that your application is not operating properly
   * and should be removed from the current rotation of Player Applications.
   * Calling `error` will typically end up with your application being disposed and destroyed from working memory.
   *
   * ```typescript title="Informing the Player that there was an unresolvable error"
   * enplug.appStatus.error('Could not preload resources');
   * ```
   *
   * @param errorMessage - Error message to be passed to the logs.
   * @returns Resolves to true if the operation has completed successfully.
   */
  error(errorMessage?: string): Promise<boolean> {
    return this.bridge.send(Service.AppStatus, Action.Error, errorMessage);
  }

  /**
   * Informs the Player whether the app can be taken offscreen.
   *
   * Sometimes your app will be displaying a video or some other content that should not be interrupted.
   * If you wish to stop the Player from replacing your app on screen,
   * use the {@link canInterrupt} property and `setCanInterrupt` function.
   *
   * The `canInterrupt` property is returned as a Promise resolving to a boolean value.
   * The `setCanInterrupt` function takes the new boolean value and returns a Promise resolving to the new value.
   * It is safe to assume that this value takes hold as soon as it is set.
   * Typically you will only set the value when needed.
   *
   * ```typescript title="Connecting the setCanInterrupt call to video events"
   * myVideo.addEventListener('play', (playEvent) => {
   *   enplug.appStatus.setCanInterrupt(false);
   * });
   * myVideo.addEventListener('ended', (endEvent) => {
   *   enplug.appStatus.setCanInterrupt(true);
   * });
   * myVideo.play();
   * ```
   *
   * @param canInterrupt - `true` - the Player should be able to take the app offscreen,<br />
   * `false` - the app should not be taken offscreen.
   * @returns Resolves to the provided `canInterrupt` state after Player confirms this change.
   */
  setCanInterrupt(canInterrupt: boolean): Promise<boolean> {
    // The SDK is compiled to regular JS and this method might be called with a wrong type.
    if (typeof canInterrupt !== 'boolean') {
      return Promise.reject(
        new TypeError(`[Enplug SDK: ${this.version}] You can only set canInterrupt to a boolean value`));
    }

    // Optimistic update before we send the new value to the bridge.
    // We still need to retain the old value in case of an error from the backend.
    const previousCanInterrupt = this.canInterruptInternal;
    this.canInterruptInternal = canInterrupt;

    return this.bridge.send(Service.AppStatus, Action.SetInterrupt, { canInterrupt }).then(() => {
      return canInterrupt;
    }, (error) => {
      this.canInterruptInternal = previousCanInterrupt;
      return previousCanInterrupt;
    });
  }

  /**
   * Checks whether the app can be taken offscreen by the Player.
   *
   * @see {@link setCanInterrupt}
   *
   * ```typescript
   * const canInterrupt = await enplug.appStatus.canInterrupt;
   * // here the value of canInterrupt will be true or false
   * // depending on the previously set value
   * // this value always initializes as true
   * ```
   *
   * @returns Promise resolving to true if the app had requested not to be interrupted and false otherwise.
   */
  get canInterrupt(): Promise<boolean> {
    return Promise.resolve(this.canInterruptInternal);
  }

  /**
   * Returns the reason why an app was rendered on-screen.
   *
   * It is used for Social CMS apps. The response will contain a reason and data property.
   * Check the value of the reason property to determine if this is a new item (reason === 'event').
   *
   * For example, a response to `getTrigger()` from a social app will contain the following:
   * ```typescript
   * {
   *   reason: 'schedule' | 'event',
   *   data: {
   *     Id,
   *     SocialNetwork,
   *     SocialItemId,
   *     FeedId,
   *     CreatedTime,
   *     LastSavedTime,
   *     CreatedTime,
   *     ProfanityCount,
   *     ProfaneWords,
   *     IsApproved,
   *     IsAllowed,
   *     SecondaryText,
   *     ImageLocalPath,
   *     UserImageLocalPath
   *   }
   * }
   * ```
   *
   * @returns Reason why an app was rendered on-screen.
   */
  getTrigger(): Promise<Trigger> {
    return this.bridge.send(Service.AppStatus, Action.GetTrigger);
  }

  /**
   * Handles `enplug.status.toggleSound()` calls.
   */
  toggleSound(enabled: boolean): Promise<void> {
    return this.bridge.send(Service.AppStatus, Action.ToggleSound, { enabled });
  }

  /** @internal */
  listenForTouchEvents(body) {
    if (body) {
      body.addEventListener('click', (event: MouseEvent) => {
        console.log('[Player SDK] Screen was touched (click or touch).');
        this.bridge.send(Service.AppStatus, Action.ScreenTouched, event && event.type);
      }, true);
      body.addEventListener('keyup', (event: KeyboardEvent) => {
        console.log('[Player SDK] Screen was touched (keyup).');
        this.bridge.send(Service.AppStatus, Action.ScreenTouched, event && event.key);
      }, true);
    }
  }
}
