/**
 * Provides apps with a method to report for how long something was displayed on the screen.
 */

import Bridge from './bridge/bridge';
import { Action, Service } from './enums';

/**
 * PlayRecorder API is a part of the Player SDK used for recording how long certain asset was shown.
 * Use global `enplug.playRecorder` object to access these methods.
 *
 * This API can be used to record how long a particular screen was shown on a display.
 * An example would be recording how long an advertisement was displayed on screen to properly pay the screen provider.
 */
export default class PlayRecorder {
  /** @internal */
  constructor(private bridge: Bridge) { }

  /**
   * This function can be used to record the time that an item was displayed.
   *
   * @param referenceId - Unique identifier to track which asset we are recording a play time for.
   * @param playDuration - Time in seconds that represents how long this item was displayed.
   * @param additionalInfo - Optional string of extra information to be saved with the recorded value.
   *
   * @returns Resolves after successfully informing the Player about the report.
   */
  report<T>(assetId: string, referenceId: any, playDuration: number, additionalInfo: string = ''): Promise<T> {
    // The typing lets us add properties dynamically.
    const payload: {[k: string]: any} = {
      assetId,
      referenceId,
      playDuration,
    };

    if (additionalInfo !== '') {
      payload.additionalInfo = additionalInfo;
    }

    return this.bridge.send(Service.PlayRecorder, Action.Report, payload);
  }
}
