/**
 * An implementation of Bridge for Web based players.
 */

import Bridge from './bridge';
import { Action, OutboundMessage } from '../enums';
import { environment } from '../environment/environment';


export default class WebBridge extends Bridge {

  private destination: string;

  constructor() {
    super();
    this.destination = this.getQueryParam('origin');
    window['epBridge'] = {
      receive: this.receive.bind(this),
    };
    window.addEventListener('message', this.handleIframeMessage.bind(this));
  }

  handleIframeMessage(event: any) {
    if (event.origin === this.destination) {
      let incomingMessage = event.data;
      if (typeof event.data === 'string') {
        try {
          incomingMessage = JSON.parse(event.data);
        } catch (e) {
          incomingMessage = event.data;
        }
      }
      this.receive(incomingMessage);
    } else if (event.data && event.data.action === Action.ScreenTouched) {
      const dataWithAppToken = event.data;
      dataWithAppToken.appToken = this.getQueryParam('apptoken');
      this.sendToPlayer(dataWithAppToken);
    }
  }

  sendToPlayer(message: OutboundMessage) {
    // In order to support Electron framework, we need to make sure we can send messages to pages with destination
    // which starts with file://. That destination is not supported so, we replace it with a '*'.
    let destination = this.destination && this.destination.startsWith('file://') ? '*' : this.destination;
    destination = destination || environment.appsUrl;
    parent.postMessage(message, destination);
  }

  validateOutboundMessage(message: OutboundMessage): boolean {
    const validationResult = super.validateOutboundMessage(message);
    return validationResult;
  }
}
