/**
 * This is a main entry point. This file gets imported to client app and constructs a Bridge instance, depending on
 * available globals. Then it builds an Enplug object and exports it.
*/

import * as pkg from '../package.json';
import AppStatus from './app-status';
import Assets from './assets';
import Bridge from './bridge/bridge';
import Cache from './cache';
import CefBridge from './bridge/cef-bridge';
import DesktopAlerts from './desktop-alerts';
import JavaBridge from './bridge/java-bridge';
import Notifications from './notifications';
import PlayerEvents from './player-events';
import PlayRecorder from './play-recorder';
import Settings from './settings';
import Social from './social';
import WebBridge from './bridge/web-bridge';
import SignageOs from './signageOs';

export interface EnplugPlayerSDK {
  appStatus: AppStatus;
  assets: Assets;
  cache: Cache;
  desktopAlerts: DesktopAlerts;
  notifications: Notifications;
  off: PlayerEvents['off'];
  on: PlayerEvents['on'];
  once: PlayerEvents['once'];
  playRecorder: PlayRecorder;
  settings: Settings;
  signageOs: SignageOs;
  social: Social;
}

/**
 * Creates appropriate bridge version, depending on the detected globals.
 */
function createEnplugPlayerSDK(window: any): EnplugPlayerSDK {
  const version = (pkg as any).version;
  let bridge: Bridge;

  if (window._epBridge) {
    console.log(`[Enplug SDK: ${version}] Creating bridge from standard implementation (JavaBridge).`);
    bridge = new JavaBridge(window._epBridge);
  } else if (window._epBridgeSend) {
    console.log(`[Enplug SDK: ${version}] Creating bridge from CEF implementation (CefBridge).`);
    bridge = new CefBridge(window._epBridgeSend);
  } else {
    console.log(`[Enplug SDK: ${version}] No global sender found. Assuming WebPlayer (WebBridge).`);
    bridge = new WebBridge();
  }

  const appStatus = new AppStatus(bridge);
  const assets = new Assets(bridge);
  const cache = new Cache(bridge);
  const desktopAlerts = new DesktopAlerts(bridge);
  const events = new PlayerEvents(bridge);
  const notifications = new Notifications(bridge);
  const playRecorder = new PlayRecorder(bridge);
  const settings = new Settings(bridge);
  const signageOs = new SignageOs(bridge);
  const social = new Social(bridge);

  window.addEventListener('load', () => {
    appStatus.listenForTouchEvents(window.document.body);
  });

  return {
    appStatus,
    assets,
    cache,
    desktopAlerts,
    notifications,
    off: events.off.bind(events),
    on: events.on.bind(events),
    once: events.once.bind(events),
    playRecorder,
    settings,
    signageOs,
    social,
  };
}

export default createEnplugPlayerSDK(window);
export { createEnplugPlayerSDK };
