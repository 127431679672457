/**
 * An implementation of Bridge for Android devices (Java).
 */
import Bridge from './bridge';
import {InboundMessage, OutboundMessage} from '../enums';

export default class JavaBridge extends Bridge {
  constructor(private javaGlobal: any) {
    super();
    this.javaGlobal.receive = this.receive.bind(this);
  }

  sendToPlayer(message: OutboundMessage) {
    this.javaGlobal.send(JSON.stringify(message));
  }

  /**
   * Java player will send a string message that needs to be parsed into InboundMessage before processing further.
   * NOTE: While the `message` is known be a string, Typescript doesn't allow to change the type of the parameter
   * in the subclass method, so we have to go with `any`.
   */
  receive(message: any) {
    try {
      const jsonMessage = JSON.parse(message) as InboundMessage;
      super.receive(jsonMessage);
    } catch (e) {
      // Ignore the message silently.
    }
  }
}
