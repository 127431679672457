/**
 * Lets apps request various types of settings that might be present on the display that's playing them.
 */

import * as pkg from '../package.json';
import Bridge from './bridge/bridge';
import { Action, Service, TransitionType } from './enums';

// TODO: use common type source - the following lines are taken from `player-web` repository
// eslint-disable-next-line no-shadow
export const enum ZoneType {
  App = 'app',
  Layout = 'layout',
  Main = 'main',
  Ticker = 'ticker',

  /** Legacy Zone type */
  Banner = 'banner',
}

// eslint-disable-next-line no-shadow
export const enum Orientation {
  Landscape = 'landscape',
  Portrait = 'portrait',
}

export interface ZoneInfo {
  height: number;
  layoutId: string;
  tickerSpeed: number;
  tickerScrollDirection: string;
  width: number;
  zone: ZoneType;

  /** Legacy setting */
  size?: number;
}

export interface SettingsState {
  /** Clear cache frequency in days */
  clearCacheFreq: number;
  /** Format: 09:00:00Z */
  dailyRebootTime: string;
  heartbeatInterval: number;
  hideWhitelabel: boolean;
  /** Is the Player connected to a 4K display? */
  is4kScreen: boolean;
  isLegacyDevice: boolean;
  /** Is the display muted? */
  isMuted: boolean;
  isOnDevice: boolean;
  isProxyEnabled: boolean;
  isSignageOs: boolean;
  /** The saved language for the current Display Group playing the app. */
  locale: string;
  /** The Display Group's set orientation (portrait or landscape). */
  orientation: Orientation;
  proofOfPlayEnabled: boolean;
  proofOfPlayReceiptUrl: string;
  proofOfPlaySendInterval: number;
  rebootDaily: boolean;
  showAppAlerts: boolean;
  showSocialAlerts: boolean;
  showWifiIcon: boolean;
  soundEnabled: boolean;
  timezone: string;
  /** The current Display Group's saved animation used by the Player to transition between applications. */
  transitionType: string;
  /** The value of the white label set for a given Player. */
  whitelabel: string;
  /**
   * An object containing the app's width, height, size, and zone,
   * available for zoning widgets as well as the main app.
   */
  zoningInfo: ZoneInfo;
  // Optional
  deviceName?: string;
  displayGroupName?: string;
}

/**
 * Settings API is a part of the Player SDK.
 * It can be used to retrieve various settings that were set by
 * the user of the Enplug Player your application is currently playing on.
 * Use global `enplug.settings` object to access these methods.
 *
 * ```typescript title="Example usage of the Settings API"
 * const settings = await enplug.settings.all;
 * ```
 *
 * Useful commands:
 * - {@link all|`enplug.settings.all`} - returns all device settings
 * - {@link deviceId|`enplug.settings.deviceId`} - returns deviceId
 * - {@link_deviceInfo|`enplug.settings.deviceInfo`} - returns deviceId, deviceName, displayHeight, displayWidth, venueId, venueName
 */
export default class Settings {

  private is4KCache: boolean;
  private version = (pkg as any).version as string;
  private localesMap: { [key: string]: string } = {
    'ar': 'ar_SA',
    'cs': 'cs_CZ',
    'de-de': 'de_DE',
    'en-us': 'en_US',
    'es-es': 'es_ES',
    'fi-fi': 'fi_FI',
    'fr-fr': 'fr_FR',
    'hu': 'hu_HU',
    'it-it': 'it_IT',
    'ja': 'ja_JP',
    'nl-nl': 'nl_NL',
    'pl': 'pl_PL',
    'pt-br': 'pt_BR',
    'ro': 'ro_RO',
    'ru': 'ru_RU',
    'sk': 'sk_SK',
    'sl': 'sl_SI',
    'th': 'th_TH',
  };

  /** @internal */
  constructor(private bridge: Bridge) { }

  /**
   * This Promise will resolve to an Object with all of the apps and device's settings.
   *
   * ```typescript title="Fetching settings"
   * const settings = await enplug.settings.all;
   * ```
   *
   * Calling the above will fetch the settings.
   *
   * @returns Resolves to an object with app and device settings.
   */
  get all(): Promise<SettingsState> {
    return this.bridge.send(Service.Settings, Action.GetAll).then((payload: any) => {
      console.log(`[Enplug SDK: ${this.version}] Settings: Returning all settings: ${JSON.stringify(payload)}`);
      if (payload && payload.locale) {
        payload.locale = this.mapLocaleCode(payload.locale);
      }
      return payload;
    });
  }

  /**
   * This Promise will resolve to the ID of current device.
   *
   * ```typescript title="Fetching device ID"
   * const settings = await enplug.settings.deviceId;
   * ```
   *
   * @returns Resolves to the ID of the current device.
   */
  get deviceId(): Promise<string> {
    return this.bridge.send(Service.Settings, Action.GetDeviceId).then((payload) => {
      const id = this.getPayloadValue(payload);
      if (id) {
        console.log(`[Enplug SDK: ${this.version}] Settings: Returning setting deviceId: ${id}`);
        return id as string;
      } else {
        return '';
      }
    }, () => '');
  }

  /**
   * @internal
   * @deprecated Use {@link all|`enplug.settings.all`}
   */
  get is4K(): Promise<boolean> {
    if (this.is4KCache) {
      return Promise.resolve(this.is4K);
    }

    return this.bridge.send(Service.Settings, Action.Is4K).then((payload) => {
      const is4kScreen = this.getPayloadValue(payload);
      console.log(`[Enplug SDK: ${this.version}] Settings: Returning setting is4k: ${is4kScreen}`);
      this.is4KCache = is4kScreen;
      return this.is4KCache;
    }, () => false);
  }

  /**
   * @internal
   * @deprecated Use {@link all|`enplug.settings.all`}
   */
  get transitionType(): Promise<TransitionType> {
    return this.bridge.send(Service.Settings, Action.TransitionType).then((payload) => {
      const transition = this.getPayloadValue(payload);
      if (transition) {
        console.log(`[Enplug SDK: ${this.version}] Settings: Returning setting transition-type: ${transition}`);
        return transition as TransitionType;
      } else {
        return TransitionType.None;
      }
    }, () => TransitionType.None);
  }

  /**
   * @internal
   * @deprecated Use {@link all|`enplug.settings.all`}
   */
  get whitelabel(): Promise<string> {
    return this.bridge.send(Service.Settings, Action.GetWhitelabel).then((payload) => {
      const label = this.getPayloadValue(payload);
      if (label) {
        console.log(`[Enplug SDK: ${this.version}] Settings: Returning setting whitelabel: ${label}`);
        return label as string;
      } else {
        return '';
      }
    }, () => '');
  }

  /**
   * @internal
   * @deprecated Use {@link all|`enplug.settings.all`}
   */
  get isMuted(): Promise<boolean> {
    return this.bridge.send(Service.Settings, Action.IsMuted).then((payload) => {
      const muted = this.getPayloadValue(payload);
      if (muted) {
        console.log(`[Enplug SDK: ${this.version}] Settings: Returning setting is-muted: ${muted}`);
        return muted as boolean;
      } else {
        return false;
      }
    }, () => false);
  }

  /**
   * @internal
   * @deprecated Use {@link all|`enplug.settings.all`}
   */
  get zoning(): Promise<any> {
    return this.bridge.send(Service.Settings, Action.GetZoningInfo).then((payload) => {
      const zoningInfo = this.getPayloadValue(payload);
      if (zoningInfo) {
        console.log(`[Enplug SDK: ${this.version}] Settings: Returning zoning info: ${JSON.stringify(zoningInfo)}`);
        return zoningInfo;
      } else {
        return null;
      }
    });
  }

  /**
   * @internal
   * @deprecated Use {@link all|`enplug.settings.all`}
   */
  get locale(): Promise<string> {
    return this.bridge.send(Service.Settings, Action.GetLocale).then((payload) => {
      const localeCode = this.getPayloadValue(payload);
      if (localeCode) {
        console.log(`[Enplug SDK: ${this.version}] Settings: Returning locale info: ${JSON.stringify(localeCode)}`);
        return this.mapLocaleCode(localeCode);
      } else {
        return 'en';
      }
    });
  }

  /**
   * Extracts SDK payload in a backwards compatible matter. Accepts payloads as both payload = \{'value': \<response\>\}
   * and payload = \<response\>;
   *
   * @internal
   */
  getPayloadValue(payload: any) {
    if (payload === true || payload === false) {
      return payload;
    }
    if (!payload) {
      return '';
    }
    // For non-objects (string, boolean) return payload itself.
    if (typeof payload !== 'object') {
      return payload;
    }
    // If object has property 'value', return it. If it doesn't, return payload object itself.
    if (payload.hasOwnProperty('value')) {
      return payload.value;
    }
    return payload;
  }

  /**
   * Maps locale code from the server format to one used by the apps.
   *
   * @param inputLocale - Locale as sent in the schedule.
   */
  mapLocaleCode(inputLocale: string): string {
    return this.localesMap[inputLocale] ? this.localesMap[inputLocale] : inputLocale;
  }

  /**
   * This Promise will resolve to an Object with the following info of the device
   * deviceId, deviceName, displayHeight, displayWidth, venueId, venueName 
   * 
   * @returns Resolves to an object with device basic info.
   */
  get deviceInfo(): Promise<any> {
    return this.bridge.send(Service.Settings, Action.GetDeviceInfo).then((payload: any) => {
      console.log(`[Enplug SDK: ${this.version}] Settings: Returning device info: ${JSON.stringify(payload)}`)
      return this.getPayloadValue(payload);
    });
  }
}
