/**
 * Relays Java/Web Player events to apps that subscribe to them.
 */

import * as pkg from '../package.json';
import Bridge from './bridge/bridge';
import { Action, Service } from './enums';

/**
 * The Event API for Player apps has methods for adding and removing event handlers.
 * Events include system events as well as custom app events generated via push notifications.
 * The Player SDK has a few events that are fired during specific moments of the application life-cycle.
 * Below you can find the various events and what to expect to be passed to the event handler.
 *
 * ---
 *
 * **The `destroy` event**
 *
 * The `destroy` event is fired anytime the application is going to be disposed by the player.
 * When developing a player application with the Player SDK,
 * you should be prepared for your app to be destroyed anytime it is taken off of the display.
 * The player will fire the `destroy` event and then wait for a small amount of time to allow you to do any cleanup.
 * To notify the player you are done with any final processes, a callback is passed to the event handler.
 * If you do not call the callback, your app will still be removed once the allotted cleanup time has passed.
 * Every JavaScript Player application should attach a handler, if only to call the `done` callback.
 *
 * ```typescript
 * enplug.on('destroy', (done) => {
 *   // perform some synchronous action
 *   localStorage.setItem('last-viewed', view.id);
 *   done(); // ok! I'm ready to be destroyed...
 * });
 * ```
 *
 * Useful commands:
 * - {@link on|`enplug.on()`}
 * - {@link off|`enplug.off()`}
 * - {@link once|`enplug.once()`}
 */
export default class PlayerEvents {

  private handlerMap: Map<string, Set<(...args) => any>> = new Map();
  private version = (pkg as any).version as string;

  /** @internal */
  constructor(private bridge: Bridge) {
    this.bridge.setEventsBus(this);
  }

  /**
   * Lets apps listen for events. The events are triggered by messages with `service == 'event'` coming from the Player
   * through the bridge.
   *
   * Can be used to attach a new handler for a specific event by that event’s name.
   *
   * ```typescript title="Adding event handler"
   * enplug.on('my-event', (eventData) => {
   *   // ...
   * });
   * ```
   *
   * If you wish to remove this event handler at any time,
   * you will need to keep a reference to the handler function and pass it into the off function.
   *
   * @see {@link off} to remove an event handler
   *
   * @param eventName - Name of the event.
   * @param eventHandler - An event handler, called when the event occurs.
   */
  on(eventName: string, eventHandler: (...args: any[]) => any): Promise<void> {
    this.checkArgumentTypes(eventName, eventHandler);
    if (this.handlerMap.has(eventName)) {
      this.handlerMap.get(eventName).add(eventHandler);
    } else {
      this.handlerMap.set(eventName, new Set([eventHandler]));
    }

    return this.bridge.send(Service.Event, Action.SetListener, { eventName });
  }

  /**
   * Removes a handler function from listening to the event.
   *
   * @param eventName - Name of the event.
   * @param eventHandler - Handler function to remove.
   */
  /**
   * Removes a handler function from listening to the event.
   *
   * Can be used to attach a new handler for a specific event by that event’s name.
   * If you wish to remove this event handler at any time,
   * you will need to keep a reference to the handler function and pass it into the off function.
   *
   * @see {@link on} to add an event handler
   *
   * ```typescript title="Adding and removing event handler"
   * function handler(eventData) {
   *   // ...
   * }
   * enplug.on('my-event', handler);
   * enplug.off('my-event', handler);
   * ```
   *
   * @param eventName - Name of the event.
   * @param eventHandler - Handler function to remove.
   */
  off(eventName: string, eventHandler: (...args: any[]) => any) {
    this.checkArgumentTypes(eventName, eventHandler);

    if (this.handlerMap.has(eventName)) {
      const handlerSet = this.handlerMap.get(eventName);

      if (handlerSet.has(eventHandler)) {
        handlerSet.delete(eventHandler);
      }
    }
  }

  /**
   * Lets apps listen for an event. After it occurs once, it removes the listener.
   *
   * The once function is a convenience function for adding an event handler
   * that gets automatically removed after the first time it is fired.
   * The code below is the functional equivalent of the once function’s functionality.
   *
   * ```typescript
   * function handler(eventData) {
   *   enplug.off('my-event', handler);
   *   // your handler run here
   * }
   * enplug.on('my-event', handler);
   * ```
   *
   * @param eventName - Name of the event.
   * @param eventHandler - Handler function to fire once when the event occurs.
   */
  once(eventName: string, eventHandler: (...args: any[]) => any): Promise<void> {
    this.checkArgumentTypes(eventName, eventHandler);

    const tmpFn = (...args) => {
      this.off( eventName, tmpFn );
      eventHandler(...args);
    };

    return this.on(eventName, tmpFn);
  }

  /**
   * Calls all handler functions associated with the event.
   *
   * @param eventName - Name of the event.
   * @param args - All arguments passed to this function besides `eventName`.
   */
  fireEvent(eventName: string, ...args) {
    const handlerSet = this.handlerMap.get(eventName);
    if (handlerSet) {
      handlerSet.forEach((handler) => {
        handler(...args);
      });
    }
  }

  /**
   * Checks whether arguments passed to the event pubsub functions are valid. The SDK may be used by JS clients
   * without type safety, so this is necessary.
   */
  private checkArgumentTypes(eventName, eventHandler) {
    if (eventName == null || typeof eventName !== 'string' ) {
      throw new TypeError(`[Enplug SDK: ${this.version}] An event name is required to attach an event handler`);
    }

    if (eventHandler == null || typeof eventHandler !== 'function' ) {
      throw new TypeError(`[Enplug SDK: ${this.version}] A handler function is required for .on/.off/.once`);
    }
  }
}
