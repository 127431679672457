/**
 * Provides a method for an app to post a notification to the Player.
 */

import Bridge from './bridge/bridge';
import { Action, Service } from './enums';

/**
 * Notifications API is a part of the Player SDK used for launching alerts to the Player.
 * Use global `enplug.notifications` object to access these methods.
 *
 * You may have noticed these types of alerts created by the Social App
 * when new posts are received by the Player.
 * A notification consists of an icon and a message.
 *
 * :::note
 * If the user has disabled alerts for their display
 * your notifications will be automatically suppressed by the Player.
 * :::
 */
export default class Notifications {
  /** @internal */
  constructor(private bridge: Bridge) {}

  post(message: string): Promise<string> {
    return this.bridge.send<{ notificationId: string }>(Service.Notifications, Action.Post, { message }).then(
      (payload) => payload ? payload.notificationId : ''
    );
  }
}
