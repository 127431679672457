/**
 * This class defines four methods for apps to request assets and themes from the Player.
 */

import Bridge from './bridge/bridge';
import { Action, Service } from './enums';

/**
 * Desktop Alerts API is a part of the Player SDK used for Desktop Alerts.
 * Use global `enplug.desktopAlerts` object to access these methods.
 */
export default class DesktopAlerts {
  /** @internal */
  constructor(private bridge: Bridge) { }

  error(): Promise<any> {
    return this.bridge.send(Service.DesktopAlerts, Action.Error);
  }
  getNext(): Promise<any> {
    return this.bridge.send(Service.DesktopAlerts, Action.GetNext);
  }
  hide(): Promise<any> {
    return this.bridge.send(Service.DesktopAlerts, Action.Hide);
  }
  openUrl(url: string): Promise<any> {
    return this.bridge.send(Service.DesktopAlerts, Action.OpenUrl, url);
  }
  ready(): Promise<any> {
    return this.bridge.send(Service.DesktopAlerts, Action.Ready);
  }
}
