/**
 * A class for communicating between social apps and the Enplug player.
 */

import Bridge from './bridge/bridge';
import { Action, Service } from './enums';

/**
 * Social API is a part of the Player SDK used for fetching social media related items.
 * Use global `enplug.social` object to access these methods.
 */
export default class Social {
  /** @internal */
  constructor(private bridge: Bridge) { }

  getFeeds(): Promise<any[]> {
    return this.bridge.send(Service.Social, Action.GetFeeds);
  }

  getItems(): Promise<SocialItem[]> {
    return this.bridge.send(Service.Social, Action.GetItems);
  }
}
