import Bridge from './bridge/bridge';
import { Action, Service } from './enums';

/**
 * SignageOs API is a part of the Player SDK used for play videos on Tizen/LG devices.
 * Use global `enplug.signageos` object to access these methods.
 */
export default class SignageOs {
  /** @internal */
  constructor(private bridge: Bridge) { }

  /**
   * This function can be used play videos on Tizen/LG devices.
   */
  playVideo<T>(signageOsItem: any): Promise<T> {
    const payload: {[k: string]: any} = {
      signageOsItem,
    };
    return this.bridge.send(Service.SignageOs, Action.PlayVideo, payload);
  }
}
