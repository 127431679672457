/**
 * An implementation of the Bridge for Windows (CEF) Players.
 */
import Bridge from './bridge';
import { InboundMessage, OutboundMessage } from '../enums';

export default class CefBridge extends Bridge {

  constructor(private cefGlobalSend: (value: any) => void) {
    super();
    window['_epBridge'] = {
      receive: this.receive.bind(this),
    };
  }

  sendToPlayer(message: OutboundMessage) {
    return this.cefGlobalSend({
      request: JSON.stringify(message),
      persistent: false,
    });
  }

  /**
   * CefPlayer will send a string message that needs to be parsed into InboundMessage before processing further.
   * NOTE: While the `message` is known be a string, TypeScript doesn't allow to change the type of the parameter
   * in the subclass method, so we have to go with `any`.
   */
  receive(message: any) {
    try {
      const jsonMessage = JSON.parse(message) as InboundMessage;
      super.receive(jsonMessage);
    } catch (e) {
      // Ignore the message silently.
    }
  }
}
