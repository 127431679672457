/**
 * An abstract class defining the basic behavior and interface of every kind od bridge in the SDK.
 * Concrete implementations need only to implement `sendToPlayer` method.
 */

import * as pkg from '../../package.json';
import PlayerEvents from '../player-events';
import {
  Action,
  InboundMessage,
  OutboundMessage,
  Service,
} from '../enums';

export default abstract class Bridge {
  version: string = (pkg as any).version;
  resolveMap: Map<string, ((value?: any) => void)[]> = new Map();

  private playerEvents: PlayerEvents;

  constructor() { }

  /**
   * Validates the data to be sent to the player and sends them.
   * It assigns a `token` to the request. It creates and returns a promise, which resolve functions are stored in
   * `resolveMap` property. These are fired when `receive` method gets a respose with matching `token`.
   *
   * @param service - Informing the player of the kind od request being sent here.
   * @param action - Informing the player of the specific action to be taken upon this request.
   * @param payload - Additional data to be sent along the request.
   */
  send<T>(service: Service, action: Action, payload?: any): Promise<T> {
    const appToken = this.getQueryParam('apptoken');
    const token = this.createToken();
    const appUrl = this.getAppUrl();
    const message: OutboundMessage = {
      service,
      action,
      payload,
      token,
      appToken,
      appUrl,
      playerSdkVersion: this.version,
    };

    if (this.validateOutboundMessage(message)) {
      return new Promise<T>((resolve, reject) => {
        this.resolveMap.set(token, [resolve, reject]);
        this.sendToPlayer(message);
      }).catch((err) => {
        console.log(`[Enplug SDK: ${this.version}] Promise error: ${err}`);
      }) as Promise<T>;
    } else {
      return Promise.reject(`[Enplug SDK: ${this.version}] Message invalid.`);
    }
  }

  /**
   * Handles incoming message and dispatches it for apps consumption.
   *
   * @param message - A message that arrived from the player.
   */
  receive(message: InboundMessage) {
    // Payload is of type object but not an array
    if (message && typeof message.payload === 'object' && !Array.isArray(message.payload)) {
      message.payload = this.replaceMediaUrlsWithBlobUrls(message.payload);
    }
    // Payload is an array.
    if (message && Array.isArray(message.payload)) {
      const updatedPayload = [];
      for (const data of message.payload) {
        const updatedData = this.replaceMediaUrlsWithBlobUrls(data);
        updatedPayload.push(updatedData);
      }
      message.payload = updatedPayload;
    }

    // Reload is not initiated by an App. As such, it doesn't need to dispatch any responses to it.
    if (message.action === Action.Reload) {
      return window.location.reload();
    }

    if (message.token && this.resolveMap.has(message.token)) {
      const promiseResolutionFunctions = this.resolveMap.get(message.token);
      this.dispatchMessageToApp(message, promiseResolutionFunctions);
    } else if (message.service === Service.Event) {
      this.dispatchEvent(message);
    }
  }

  /**
   * Recursively iterates through a given object and replaces all of the instances of media URLs
   * (specified in a blobs map) with an ObjectURL.
   *
   * @param data - Object within which all of media URLs are replaced with ObjectURLs
   * @param blobs - Optional mapping of media URLs to Blobs.
   */
  replaceMediaUrlsWithBlobUrls(data: any, blobs?: Map<string, Blob>): any {
    if (!data) {
      return data;
    }
    const blobUrls = {};
    blobs = blobs || data.blobs;
    if (!blobs || !blobs.size) {
      return data;
    }
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (data[key] && typeof data[key] === 'object' && key !== 'blobs') {
          data[key] = this.replaceMediaUrlsWithBlobUrls(data[key], blobs);
        } else if (blobs.has(data[key])) {
          const originalUrl = data[key];
          data[key] = window.URL.createObjectURL(blobs.get(data[key]));
          blobUrls[data[key]] = originalUrl;
        }
      }
    }

    data.blobUrls = blobUrls;
    return data;
  }

  /**
   * Actually sends the data to the player. Concrete implementation depends on the player type.
   */
  abstract sendToPlayer(message: OutboundMessage);

  /**
   * Sets the PlayerEvents instance for this bridge for player events passing.
   */
  setEventsBus(playerEvents: PlayerEvents) {
    this.playerEvents = playerEvents;
  }

  /**
   * Gets a value from URL query by key.
   */
  public getQueryParam(key: string, queryUrl?: URL) {
    const url = queryUrl || new URL(window.location.href);
    const params = url.searchParams;
    const legacyParams: Map<string, string> = new Map();

    // Older browsers don't support searchParams
    if (!params && typeof url.search === 'string') {
      const searchParams = url.search.replace('?', '').split('&');
      for (const param of searchParams) {
        const paramParts = param.split('=');
        legacyParams.set(paramParts[0], paramParts[1]);
      }
    }

    if (params) {
      return params.get(key) || '';
    }
    return legacyParams.get(key) || '';
  }

  /**
   * Checks whether the message is good to be sent.
   */
  protected validateOutboundMessage(message: OutboundMessage): boolean {
    return message && message.service != null && message.action != null;
  }

  /**
   * Takes appropriate action depending on a message type.
   * If the message has `isError` flag set, it will call the `reject` function.
   * If the message has `Reload` action, it will reload the browser window.
   * Otherwise, it will call resolve function.
   *
   * @param promiseResolutionFunctions - A resolve and reject funtions in an array.
   */
  private dispatchMessageToApp(message: InboundMessage, promiseResolutionFunctions: ((value?: any) => void)[]) {
    try {
      const [resolve, reject] = promiseResolutionFunctions;
      resolve(message && message.payload);
    } catch (err) {
      console.warn(`[Enplug SDK: ${this.version}] Error dispatching message to app: ${err}`);
    }
  }

  /**
   * Deals with incoming events. The action parameter will be used as a event name and handlers
   * for that event name will be fired. If the event name is `destroy`, the handler parameters will be set to a `done`
   * function.
   */
  private dispatchEvent(message: InboundMessage) {
    // In case of action === 'destroy', the argument passed to the event handler has to be a "done" callback.
    if (message.action === Action.Destroy) {
      const done = () => {
        return this.send(Service.Status, Action.DestroyFinished);
      };
      this.playerEvents.fireEvent(message.action, done);
    } else {
      this.playerEvents.fireEvent(message.action, message.payload);
    }
  }

  /**
   * Creates a unique string token.
   */
  private createToken() {
    const token = Math.random().toString(36).substr(2);
    // Make sure a unique token is created. If created token already exists, create a different one.
    if (this.resolveMap.has(token)) {
      return this.createToken();
    }
    return token;
  }

  /**
   * Returns a value for `appUrl` property in the outbound message.
   */
  private getAppUrl(): string {
    return `${window.location.host}${window.location.pathname}`;
  }
}
