/**
 * This class defines four methods for apps to request assets and themes from the Player.
 */

import Bridge from './bridge/bridge';
import { Action, Service } from './enums';

/**
 * Cache API is a part of the Player SDK used for loading files into cache.
 * Use global `enplug.cache` object to access these methods.
 *
 * ```typescript title="Example usage of the Cache API"
 * const preloadedBlobUrl = await enplug.cache.getFile('https://someurl.com/somefile.jpg');
 * ```
 *
 * Useful commands:
 * - {@link getFile|`enplug.assets.getFile()`} - loads file into cache
 */
export default class Cache {
  /** @internal */
  constructor(private bridge: Bridge) {}

  getFile(url: string): Promise<string> {
    return this.bridge.send(Service.Cache, Action.GetFile, url).then((blob: Blob) => {
      if (blob) {
        return window.URL.createObjectURL(blob);
      }
      return url;
    });
  }
}
